import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import Button from '../../../components/Form/Button';
import SelectionModal from '../Components/SelectionModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createProject as createNewProject,
  setProjectTypeSelected,
} from '../store/actions/homeActions';
import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import { CurrentProjectDetails, ProjectType } from '../models/homepageModel';
import { login, useSession } from '../../../utils/Auth';
import {
  setAmbientConditionValues,
  setCurrentApplication,
  setFetchedTransformerResult,
  setTransformerInputData,
} from '../../Workspace/store/WorkspaceReducer';
import {
  initialApplicationData,
  initialResultData,
  transformerDefaultInputValues,
} from '../../Workspace/Dimensioning/models/TransformerDefaultValues';
import ProjectTypeSelectionModal from './ProjectTypeSelectionModal';
import Cookies from 'js-cookie';
import { useCreateProjectMutation } from '../services/APIMainService';
import notificationManager from '../../../utils/NotificationManager';
import { RootState } from '../../../store/rootReducer';

interface LoginModalProps {
  modalOpen?: boolean;
  onClose?: () => void;
  projectData: CurrentProjectDetails[] | undefined;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginModal: React.FunctionComponent<LoginModalProps> = ({
  modalOpen,
  onClose,
  projectData,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { session } = useSession();
  const [createProject] = useCreateProjectMutation({
    fixedCacheKey: 'shared-createproject-post',
  });

  const newProjectInfo = useSelector(
    (state: RootState) => state.home.newProjectData,
  );

  const [newProjectFormModalOpen, setNewProjectFormModalOpen] =
    React.useState(false);

  const [viewProjectTypeSelectionModal, setViewProjectTypeSelectionModal] =
    useState(false);

  const onNewProjectFormModalClose = () => {
    setNewProjectFormModalOpen(false);
  };

  const isLoggedIn = session?.user;

  const onSkipClick = () => {
    onClose?.();
    setNewProjectFormModalOpen(true);
  };

  const handleOnClose = () => {
    onClose?.();
    setNewProjectFormModalOpen(false);
    setViewProjectTypeSelectionModal(false);
  };

  const handleProjectOptionClick = (option: number) => {
    dispatch(setProjectTypeSelected(option.toString()));

    if (option === ProjectType.DriveMotor) {
      if (process.env.REACT_APP_PLC_CKNAME) {
        const date = new Date();
        Cookies.set(
          process.env.REACT_APP_PLC_CKNAME,
          date.getTime().toString(),
          {
            expires: 1,
            path: '/',
            domain: '.goselect.motion.abb.com',
          },
        );
      }
      navigate('/workspace');
      dispatch(setCurrentApplication(-1));
      dispatch(
        setTransformerInputData({
          transformerInput: transformerDefaultInputValues,
          selectionInputs: [initialApplicationData],
        }),
      );
      dispatch(
        setAmbientConditionValues({
          altitude: '1000',
          driveTemparature: '40',
          motorTemparature: '40',
          transformerTemparature: '40',
        }),
      );
      dispatch(
        setFetchedTransformerResult({
          transformer: {
            transformerResult: [
              { typeDesignation: '-', selectionData: initialResultData },
            ],
          },
        }),
      );
    }

    if (option === ProjectType.PLCHMI) {
      if (process.env.REACT_APP_PLC_CKNAME) {
        const date = new Date();
        Cookies.set(
          process.env.REACT_APP_PLC_CKNAME,
          date.getTime().toString(),
          {
            expires: 1,
            path: '/',
            domain: '.goselect.motion.abb.com',
          },
        );
        Cookies.set('isUserLoggedIn', `${Boolean(session?.user)}`, {
          expires: 1,
          path: '/',
          domain: '.goselect.motion.abb.com',
        });
      }

      const url = process.env.REACT_APP_PLC_REDIRECT
        ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${sessionStorage.getItem('id')}`
        : '';
      window.location.href = url;
    }

    setViewProjectTypeSelectionModal(false);
  };

  const onProjectTypeSelectionModalClose = () => {
    setViewProjectTypeSelectionModal(false);
  };

  const onCreateProject = (projectType: ProjectType) => {
    createProject({
      userToken: session?.user.access_token,
      projectData: Object.assign({}, newProjectInfo, {
        projectType: projectType,
      }),
    }).then((response) => {
      if (response.data) {
        sessionStorage.setItem('id', response.data.projectId);
        sessionStorage.setItem('name', response.data.projectName);
        dispatch(createNewProject(response.data));
        handleProjectOptionClick(projectType);
      } else {
        notificationManager.error(t('Unable to create project'));
      }
    });
  };

  const handleTypeBackClick = () => {
    console.log('clicked');
    setViewProjectTypeSelectionModal(false);
    setNewProjectFormModalOpen(true);
  };

  const onNextClick = () => {
    setViewProjectTypeSelectionModal(true);
    onNewProjectFormModalClose();
    onClose?.();
  };
  return (
    <>
      {!isLoggedIn ? (
        <>
          <Modal
            open={modalOpen}
            onModalClose={onClose}
            modalTitle={t(I18.login_modal_title)}
            showCloseButton={true}
            type="default"
            showHederSeparator="true"
            backdropClick={false}
            className="login-modal"
            data-testid="login-modal"
          >
            <div
              id="login-modal-content"
              data-rf="login-modal-content"
              className="modal-content modal-container"
              slot="content"
            >
              <div data-rf="login-modal-description">
                {t(I18.login_modal_description)}
              </div>
            </div>
            <div className="modal-button-container" slot="footer">
              <div className="open-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  disabled={false}
                  onBtnClick={login}
                  className="open-button"
                  data-rf="login-modal-login-button"
                >
                  {t(I18.modal_login_button)}
                </Button>
              </div>
              <div className="cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={onSkipClick}
                  className="cancel-button"
                  data-rf="login-modal-skip-button"
                >
                  {t(I18.modal_login_skip_button)}
                </Button>
              </div>
            </div>
          </Modal>

          <SelectionModal
            modalOpen={newProjectFormModalOpen}
            handleOnClose={handleOnClose}
            onNextClick={onNextClick}
            projectData={projectData}
          />
          <ProjectTypeSelectionModal
            onCreateProject={onCreateProject}
            modalOpen={viewProjectTypeSelectionModal}
            onClose={onProjectTypeSelectionModalClose}
            handleTypeBackClick={handleTypeBackClick}
          />
        </>
      ) : (
        <>
          <SelectionModal
            modalOpen={isLoggedIn ? modalOpen : newProjectFormModalOpen}
            handleOnClose={
              isLoggedIn ? handleOnClose : onNewProjectFormModalClose
            }
            onNextClick={onNextClick}
            projectData={projectData}
          />
          <ProjectTypeSelectionModal
            onCreateProject={onCreateProject}
            modalOpen={viewProjectTypeSelectionModal}
            onClose={onProjectTypeSelectionModalClose}
            handleTypeBackClick={handleTypeBackClick}
          />
        </>
      )}
    </>
  );
};

export default LoginModal;
