import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { getCountryForTimezone } from 'countries-and-timezones';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { RootState } from '../store/rootReducer';
TimeAgo.addDefaultLocale(en);

export const timeAgo = new TimeAgo('en-US');

export const generateKey = (pre: string | number) => {
  return `${pre}_${new Date().getTime()}`;
};

/**
 * Get Current Country Information
 */
export const GetCurrentCountry = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountryForTimezone(tz);
  return {
    id: country?.id,
    idLowerCase: country?.id.toLocaleLowerCase(),
    country: country?.name,
  };
};

export type ErrorData = FetchBaseQueryError | SerializedError;

export function roundDecimal(value: number): number {
  return value < 0.8 ? parseFloat(value.toFixed(3)) : Math.ceil(value);
}

export const GetNameConverted = (name: string) => {
  if (name.length <= 7) {
    return name;
  } else {
    return name.slice(0, 4) + '...' + name.slice(-3);
  }
};

export const setSessionStorageValue = (state: any) => {
  try {
    const SerializedStateValue = JSON.stringify(state);
    sessionStorage.setItem('persist:root', SerializedStateValue);
  } catch (error) {
    console.log(error);
  }
};

export const getSessionStorageValue = () => {
  try {
    const state = sessionStorage.getItem('persist:root');
    if (state === null) return undefined;
    return JSON.parse(state);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const deleteSessionStorageValue = () => {
  try {
    sessionStorage.removeItem('persist:root');
  } catch (error) {
    console.log(error);
  }
};

export const replaceYearText = (
  rowIds: string | undefined | null,
  text: string,
) => {
  const year = rowIds !== null ? '2023' : '2017';
  return text ? text.replace('_YEAR_', year) : null;
};
