import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import Home from './pages/Home';
import Projects from './pages/Projects';
import { TranslateProvider } from './i18translate';
import { TranslationConfig } from './i18translate/models/TranslationConfig';
import WorkspaceContainer from './pages/Workspace/Components/WorkspaceContainer';
import LogoutHandler from './LogoutHandler';
import { useGetTranslationsQuery } from './pages/Home/services/APICommonService';
import Notification from './components/Notification';
import CommonLoader from './components/Loader';
import BOMReport from './pages/Workspace/Reports/BOMReport';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { login, silentLogin, useSession } from './utils/Auth';
import Versions from './pages/Info/Version';
import Leagal from './pages/Info/Leagal';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from './services/AppInsight';
import Cookies from 'js-cookie';
import Revision from './pages/Revision';

const App = () => {
  const { data, isLoading } = useGetTranslationsQuery('en-US');
  const { session, loading: sessionLoading } = useSession();
  const allanalyticsValue = localStorage.getItem('allanalytics');
  const analyticsValue = localStorage.getItem('analytics');
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(
    allanalyticsValue === 'allanalytics' || analyticsValue === 'analytics',
  );
  const [isPageLoading, setIsPageLoading] = useState(true);
  const translationConfig: TranslationConfig = {
    currentLanguage: 'en-US',
    currentLanguageJson: data ?? {},
    fallbackLanguage: 'en-US',
    fallbackLanguageJson: data ?? {},
  };
  const isUserLoggedInPLC = Cookies.get('isUserLoggiedInPlc');

  const handleAppInsights = () => {
    if (isAnalyticsEnabled) {
      if (appInsights) {
        appInsights.config.disableTelemetry = true;
        appInsights.addTelemetryInitializer(() => true);
      }
    } else {
      if (appInsights) {
        appInsights.config.disableTelemetry = false;
        appInsights.addTelemetryInitializer(() => false);
      }
    }
  };

  document.addEventListener('localdatachanged', () => {
    setIsAnalyticsEnabled(
      localStorage.getItem('allanalytics') === 'allanalytics' ||
        localStorage.getItem('analytics') === 'analytics',
    );
  });

  useEffect(() => {
    handleAppInsights();
  }, [isAnalyticsEnabled]);

  useEffect(() => {
    if (window.top !== null && window.self !== window.top) {
      window.top.location = window.self.location; // Redirect to the app outside iframe
    }
  }, []);

  useLayoutEffect(() => {
    if (session?.user) {
      if (isUserLoggedInPLC && isUserLoggedInPLC === 'true') {
        Cookies.remove('isUserLoggiedInPlc', {
          domain: '.goselect.motion.abb.com',
        });
      }

      silentLogin()?.then(() => {
        setIsPageLoading(false);
      });

      setInterval(() => {
        silentLogin();
      }, 10 * 60000);
    } else if (!sessionLoading && !session?.user) {
      setIsPageLoading(false);
    }
  }, [sessionLoading]);

  useLayoutEffect(() => {
    if (isUserLoggedInPLC && isUserLoggedInPLC === 'true') {
      login()?.then(() => {
        setIsPageLoading(false);
        Cookies.remove('isUserLoggiedInPlc', {
          domain: '.goselect.motion.abb.com',
        });
      });
    }
  }, [isUserLoggedInPLC]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/workspace" element={<WorkspaceContainer />} />
          <Route path="/info/versions" element={<Versions />} />
          <Route path="/info/legal" element={<Leagal />} />
          <Route path="/revision" element={<Revision />} />
        </Route>
        <Route path="/report" element={<BOMReport />} />
        <Route path="/plclogout" element={<LogoutHandler />} />
      </>,
    ),
  );

  const initComponents = () => {
    return (
      <TranslateProvider {...translationConfig}>
        <RouterProvider router={router} />
        <React.Fragment>
          <Notification id="root-component-notification" />
          <Notification id="root-component-alert" />
        </React.Fragment>
      </TranslateProvider>
    );
  };

  return !isLoading && !isPageLoading ? (
    isAnalyticsEnabled ? (
      <AppInsightsContext.Provider value={reactPlugin}>
        {initComponents()}
      </AppInsightsContext.Provider>
    ) : (
      initComponents()
    )
  ) : (
    <CommonLoader
      size="xlarge"
      indicatorType={'circular'}
      progressType={'indeterminate'}
      progressValue={50}
      type={'primary-black'}
    />
  );
};

export default App;
