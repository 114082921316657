import {
  GeneralProjectDetails,
  NewProjectInfo,
} from '../../models/homepageModel';
import { ActionTypes } from './actionTypes';

export const createProject = (newProjectInfo: GeneralProjectDetails) => ({
  type: ActionTypes.CREATE_PROJECT,
  payload: newProjectInfo,
});
export const addProjectDetails = (newProjectInfo: NewProjectInfo) => ({
  type: ActionTypes.ADD_PROJECT_DETAILS,
  payload: newProjectInfo,
});

export const setProjectTypeSelected = (projectTypeSelected: string) => ({
  type: ActionTypes.PROJECT_TYPE_SELECTED,
  payload: projectTypeSelected,
});
