import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import StandardAndPredefinedPointsTable from './StandardAndPredefinedPointsTable';
import { useGetPDSResultsMutation } from '../services/APIEfficiencyService';

import { useEffect } from 'react';
import CommonLoader from '../../../../components/Loader';
import {
  UserDefinedLoadPoints,
  EfficiencyInputs,
  PDSResults,
} from '../../models/EfficiencyModel';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import UserDefinedLoadPointsSection from './UserDefinedLoadPoints';
import UDPTable from './UDPTable';
import { PDSLossesChart } from './PDSLossesChart';
import IESClassGraph from './IESClassGraph';
import { PDSEfficiencyPoint } from '../Utils/PDSCommon';
import ProductInfoData from './ProductInfoData';
import UDPLossesChart from './UDPLossesChart';
import {
  setEfficiencyUDPLossesData,
  setEfficiencyUdpData,
  setPDSEfficiencyDetails,
} from '../../store/WorkspaceReducer';
import ErrorPage from './ErrorPage';
import { setActiveTab } from '../../store/WorkspaceReducer';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import notificationManager from '../../../../utils/NotificationManager';
import React from 'react';
import LossDetermination from '../../Reports/LossDetermination';

export interface EfficiencyProp {
  selectedTabId: string;
}

export interface ErrorType {
  error: boolean;
  type: string;
}

const PDSEfficiency = ({ selectedTabId }: EfficiencyProp) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { session, loading } = useSession();
  const [isLoading, setIsLoading] = React.useState(false);
  const [getPDSData, status] = useGetPDSResultsMutation();

  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [errorPage, setErrorPage] = React.useState({
    error: false,
    type: '',
  } as ErrorType);

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const efficiencyData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );
  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const pdsData = workspace.pdsEfficiencyDetails;

  const errorPageButtonClick = () => {
    if (errorPage.type === 'retry') {
      setIsLoading(true);
      getPdsResult();
    } else {
      dispatch(setActiveTab('selection'));
    }
  };

  //function to call the updateproject api so we are sending the efficiency data to the api

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateProjectApiCall = (pdsresult?: any) => {
    const dimensionInput =
      efficiencyData?.dimensionDetails?.selectDriveResult?.dimension
        ?.dimensionInput?.selectionInputs?.[0];

    const lowercaseDimensionInput = {};

    for (const key in dimensionInput) {
      //@ts-ignore
      lowercaseDimensionInput[key.toLowerCase()] = dimensionInput[key];
    }
    if (projectDetails.projectId || window.sessionStorage.getItem('id')) {
      updateProject({
        userToken: session ? session?.user?.access_token : undefined,
        projectData: {
          projectId:
            (projectDetails.projectId || window.sessionStorage.getItem('id')) ??
            '',
          projectName:
            (projectDetails.projectName ||
              window.sessionStorage.getItem('name')) ??
            '',
          jsonFileName: workspace.id,
          projectJson: JSON.stringify({
            dimensionSavedData: {
              dimensionInput: dimensionInput,
              applicationOutput: {
                supplyDriveLoads:
                  efficiencyData?.dimensionDetails?.selectDriveResult?.dimension
                    ?.dimensionResult?.supplyDriveLoads,
              },
              exceptionType:
                efficiencyData?.dimensionDetails?.selectDriveResult
                  ?.exceptionType,
              message:
                efficiencyData?.dimensionDetails?.selectDriveResult?.message,
            },
            selectionSavedData: {
              industryName: workspace?.selectionDetails?.industryName,
              applicationName: workspace?.selectionDetails?.applicationName,
            },

            efficiencySavedData: {
              motorId:
                efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData
                  ?.motorId || pdsresult?.motorTechnicalData?.motorId, //motorTechnicalData
              driveTypeCode:
                efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails
                  ?.driveTypeCode || pdsresult?.pdsDriveDetails?.driveTypeCode, //driveTypeCode

              udpPoints:
                efficiencyData?.efficiencyUDPLossesdata?.map(
                  (item: { data: { speed: any; torque: any } }) => ({
                    speed: item?.data?.speed,
                    torque: item?.data?.torque,
                  }),
                ) ?? [],
              driveFrame: '', //
            },
            plusOptions: efficiencyData?.drivePlusCodeObject?.longtypeCode
              ? {
                longtypeCode:
                  efficiencyData?.drivePlusCodeObject?.longtypeCode,
                options: efficiencyData?.drivePlusCodeObject?.options,
              }
              : null,
          }),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: workspace?.id,
        },
      }).catch((error) => {
        notificationManager.error(
          'Unable to autosave the project due to: ' + error.toString(),
        );
      });
    }
  };

  useEffect(() => {
    if (
      workspace?.efficiencyUDPLossesdata &&
      Object.keys(workspace?.efficiencyUDPLossesdata).length > 0
    ) {
      updateProjectApiCall();
    }
  }, [workspace?.efficiencyUDPLossesdata]);

  useEffect(() => {
    getPdsResult();
  }, [
    dimensionData.dimensionOutputData,
    dimensionData.dimensionDataLoading,
    loading,
  ]);

  const getPdsResult = () => {
    if (!loading) {
      if (
        dimensionData.dimensionDataLoading &&
        selectedTabId === 'efficiency'
      ) {
        setIsLoading(true);
      } else if (
        selectedTabId === 'efficiency' &&
        (pdsData?.motorTechnicalData?.typeDesignation !==
          dimensionData.productTypeCode.motorTypeDesignation ||
          pdsData?.pdsDriveDetails?.driveTypeCode !==
          dimensionData.productTypeCode.driveTypeCode?.split('+')?.[0])
      ) {
        setIsLoading(true);
        if (
          dimensionData?.selectMotorResult?.dimension?.dimensionResult
            ?.supplyDriveLoads.length > 0 &&
          dimensionData?.selectMotorResult?.dimension?.dimensionResult
            ?.supplyDriveLoads?.[0]?.motorUnit
        ) {
          let motor =
            dimensionData?.selectMotorResult?.dimension?.dimensionResult
              ?.supplyDriveLoads?.[0]?.motorUnit?.results;
          let drive =
            dimensionData?.selectDriveResult?.dimension?.dimensionResult
              ?.supplyDriveLoads?.[0]?.inverterUnit?.results;
          let inputs = {} as EfficiencyInputs;
          for (let mot of motor) {
            if (mot.name === 'Catno') {
              inputs.catno = mot.value;
            }
            if (mot.name === 'CatNoOriginal' && mot.value !== '') {
              inputs.catno = mot.value;
            }
            if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
              inputs.calcID = mot.value;
            }
            if (mot.name === 'MEPS') {
              inputs.mEPS = mot.value;
            }
            if (mot.name === 'RtdConnection') {
              inputs.rtdConnection = mot.value;
            }
            if (mot.name === 'Frequency ') {
              inputs.frequency = mot.value;
            }
            if (mot.name === 'Voltage ') {
              inputs.rtdVolt = mot.value;
            }
            if (mot.name === 'Power ') {
              inputs.rtdPower = mot.value;
            }
            if (mot.name === 'Ambient') {
              inputs.ambient = mot.value;
            }
            if (mot.name === 'TypeProtection' && mot.value !== '') {
              inputs.typeProtection = mot.value;
            }
            if (mot.name === 'VarientCode' && mot.value !== '') {
              inputs.variantCode = mot.value;
            }
          }
          if (!Boolean(inputs.mEPS)) {
            inputs.mEPS = 'NOT DEFINED';
          }
          for (let dri of drive) {
            if (dri.name === 'TypeDesignation') {
              if (
                dri.value.includes('ACS180-04x') ||
                dri.value.includes('ACH180-04x')
              ) {
                inputs.driveTypeCode = dri.value.replace('04x', '04S');
              } else inputs.driveTypeCode = dri.value;
            }
          }

          if (status.isUninitialized || !status.isLoading) {
            getPDSData(inputs).then((response) => {
              if (response.data && response?.data !== null) {
                let res: PDSResults | undefined = response?.data;
                if (res?.standardPoints !== null) {
                  dispatch(setPDSEfficiencyDetails(response?.data));
                  setIsLoading(false);
                  let data = efficiencyData?.efficiencyUDPData;

                  dispatch(
                    setEfficiencyUdpData({
                      ...data,
                      speedUnit: '%',
                      torqueUnit: '%',
                      torqueOrPower: 'torque',
                    }),
                  );
                  dispatch(setEfficiencyUDPLossesData([]));
                  updateProjectApiCall(response?.data);
                } else {
                  setIsLoading(false);
                  setErrorPage({ error: true, type: 'no-data' });
                }
              } else {
                setIsLoading(false);
                setErrorPage({ error: true, type: 'retry' });
                dispatch(setPDSEfficiencyDetails(undefined));
              }
            });
          }
        }
      }
    }
  };

  //pds efficiency
  let pdsRelativeLossWithoutFormatting: any;
  if (pdsData?.standardPoints) {
    const selectedPoint = pdsData?.standardPoints.find(
      (point) =>
        parseInt(point.speed) === PDSEfficiencyPoint.Speed &&
        parseInt(point.torque) === PDSEfficiencyPoint.Torque,
    );
    if (selectedPoint?.relativeLoss) {
      pdsRelativeLossWithoutFormatting = selectedPoint.relativeLoss;
    }
  }
  return (
    <div className="efficiency-container">
      <div
        className="disp-flex, efficiency-header"
        data-testid="pds-efficiency-header"
      >
        <span>
          {!errorPage.error && !isLoading && t(I18.efficiency_heading)}
        </span>
        <span></span>
      </div>
      {isLoading && (
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      )}
      {!isLoading && pdsData?.iesClass && !errorPage.error && (
        <>
          <div className="user-defined-loadpoints-container">
            <UserDefinedLoadPointsSection
              data={pdsData}
              udpPoints={(points: UserDefinedLoadPoints[]) => { }}
            />
          </div>

          {workspace?.efficiencyUDPData && (
            <div className="pds-charts-container">
              {workspace?.efficiencyUDPLossesdata &&
                workspace?.efficiencyUDPLossesdata?.length > 0 && (
                  <>
                    <div className="efficiency-page-headers">
                      {t(I18.efficiency_user_defined_load_points_chart_header)}
                    </div>

                    {Boolean(
                      workspace?.efficiencyUDPData?.maxSpeedPercentage,
                    ) &&
                      Boolean(
                        workspace?.efficiencyUDPLossesdata?.length > 0,
                      ) && (
                        <UDPLossesChart
                          height={400}
                          loading={false}
                          type={'large'}
                          UdpData={workspace?.efficiencyUDPData}
                          partloadPoints={workspace?.efficiencyUDPLossesdata}
                        />
                      )}
                    <br />
                    <div className="efficiency-page-headers">
                      {t(I18.efficiency_user_defined_load_points_header)}
                    </div>
                    <UDPTable />
                  </>
                )}
            </div>
          )}

          <ProductInfoData />
          <div className="pds-charts-container">
            {/* component to be rendered based up on the  */}
            {pdsData?.pdsDriveDetails?.rowIds && <IESClassGraph
              rangeList={pdsData?.iesClass?.iesLimits}
              relativeLoss={pdsRelativeLossWithoutFormatting}
              rangeIesCalss={pdsData?.iesClass?.ies}
            />}

            <div
              className="efficiency-page-headers"
              data-rf="pds-losses-chart-heading"
            >
              {t(I18.pds_absolute_losses_heading)}
            </div>

            <PDSLossesChart
              height={400}
              loading={false}
              type={'large'}
              standardPoints={pdsData.standardPoints}
              fZero={pdsData.pdsDriveDetails.fZero}
            />
            <br />
            <div
              className="efficiency-page-headers"
              data-rf="pds-losses-table-heading"
            >
              {t(I18.efficiency_standard_points_header)}
            </div>
            <StandardAndPredefinedPointsTable data={pdsData.standardPoints} />
            <div className="efficiency-page-table-msg">
              {t(I18.efficiency_operating_points_table_regulation)}
            </div>
            <div className="standByLosses" data-rf="standby-losses-heading">
              <table className="energy-losses-table left-align">
                <thead>
                  <tr>
                    <th></th>
                    <th>ABSOLUTE LOSSES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t(I18.efficiency_drive_stand_by_losses)}</td>
                    <td>{pdsData.pdsDriveDetails.standbyLoss}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <LossDetermination application={efficiencyData} />
            
          </div>
        </>
      )}
      {!isLoading && errorPage.error && (
        <ErrorPage
          type={errorPage.type}
          page="pds"
          onClick={() => errorPageButtonClick()}
        />
      )}
    </div>
  );
};

export default PDSEfficiency;
